﻿import {AsYouType} from 'libphonenumber-js'

let phoneNum = document.getElementById('phone');

window.onload = function () {
    updatePhone();
}
phoneNum.addEventListener('input', function (evt) {
    updatePhone()
})

function updatePhone(){
    let formattedPhone = new AsYouType('US').input(phoneNum.value)
    
    phoneNum.value = formattedPhone
// Outputs: '(213) 373-4'    
}
